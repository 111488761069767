import React from 'react';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import publicRuntimeConfig from '@/utils/config';

class Index extends React.Component {
  render() {
    const { dataSite, detailChuong } = this.props;
    if (detailChuong) return null
    return (
      <footer >
        <div className="footer-container">
          <div className="container">
            <div className='h-mt'>
              <div className="col-md-3 col-12">
                <img
                  src={`${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${dataSite?.logo?.length > 0 && dataSite?.logo[0]?.file || ''}`}
                  height={60}
                />
                {/* <span className='footer-name'>{dataSite?.name}</span> */}
              </div>
              <div className="footer-right col-md-9 col-12" style={{ fontWeight: '400' }}>
                Copyright © 2022 <a href='https://vgasoft.vn/' target='_blank' style={{ fontWeight: '500', color: '#1175BB' }}>vgasoft.vn</a>
                <br />
                {dataSite?.seoDescriptions}
              </div>
            </div>

            <div className='h-pc'>
              <div style={{ textAlign: 'center' }}>
                Copyright © 2022 <a href='https://vgasoft.vn/' target='_blank' style={{ fontWeight: '500', color: '#1175BB' }}>vgasoft.vn</a>

              </div>
            </div>

          </div>
        </div>
      </footer>
    );
  }
}
export default Index;
